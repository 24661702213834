import React, { useEffect, useState } from "react"
import { useWindowSize } from "../hooks"
import Layout from "../components/layout"
import HeroSection from "../components/sections/heroSection"
import SEO from "../components/seo"
import config from "../../config"
import "bootstrap/dist/css/bootstrap.min.css"

const IndexPage = () => {
  const dimensions = useWindowSize()
  const [screen, setScreen] = useState(null)
  useEffect(() => {
    if (dimensions) {
      setScreen(dimensions.windowWidth)
    }
  }, [dimensions])

  return (
    <Layout isMobile={screen < 1200}>
      <SEO title={config.siteTitle} />
      <HeroSection isMobile={screen < 1200} />
    </Layout>
  )
}

export default IndexPage
