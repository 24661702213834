import React, { useEffect } from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/Underlining"
import TechnologyIcon from "../icons"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  margin: 40px auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 100px auto 50px;
  }
  background: ${({ theme }) => theme.colors.background};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 0 2rem;
    }
    .icon-container {
      padding: 0 1.5rem 0 0;
    }
    .description {
      font-size: 1.125rem;
      margin-bottom: 2rem;
    }
  }
`

const Title = styled.h1`
  margin-bottom: 1.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 1.875rem;
    margin-bottom: 0;
  }
`

const Subtitle = styled.h3`
  margin-top: -0.25rem;
  margin-bottom: 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 1.5rem;
    margin-top: 2rem;
    font-weight: 500;
  }
`

const AnimatedUnderlining = motion.custom(Underlining)

const HeroSection = ({ isMobile }) => {
  const iconArray = [
    "golang",
    "typescript",
    "kafka",
    "graphql",
    "postgres",
    "python",
    "dbt",
    "datadog",
    "cloudflare",
    "aws",
  ]
  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()
  const uControls = useAnimation()
  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    eControls.start({
      rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
      transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
    })
    gControls.start({
      opacity: 1,
      y: 0,
      transition: { delay: 0.4 },
    })
    sControls.start({
      opacity: 1,
      x: 0,
    })
    // Animate underlining to hover state
    uControls.start({
      boxShadow: `inset 0 -2rem 0 #FFF4D9`,
      transition: { delay: 0.4, ease: "circOut" },
    })
  }, [eControls, gControls, sControls, uControls])

  return (
    <StyledSection isMobile={isMobile}>
      <StyledContentWrapper isMobile={isMobile}>
        <motion.div initial={{ opacity: 0, y: 20 }} animate={gControls}>
          <Title isMobile={isMobile}>
            Full stack software engineer with 6+ years of experience working at
            internet startups and large companies.
          </Title>
          <Subtitle isMobile={isMobile}>
            I am passionate about implementing event driven architectures,
            building and deploying products and sevices built with{" "}
            <AnimatedUnderlining animate={uControls} color="tertiary" big>
              Golang and Typescript
            </AnimatedUnderlining>{" "}
            powered by{" "}
            <AnimatedUnderlining animate={uControls} color="tertiary" big>
              Kafka, GraphQL and REST API's
            </AnimatedUnderlining>
            .<br></br>
            <br></br>I also love creating and optimizing data models with{" "}
            <AnimatedUnderlining animate={uControls} color="tertiary" big>
              Python and DBT
            </AnimatedUnderlining>
            .
          </Subtitle>
          <div className="description">Favorite technologies & tools</div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={sControls}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingBottom: "2rem",
              flexWrap: "wrap",
            }}
          >
            {iconArray.map(icon => (
              <div className="icon-container">
                <TechnologyIcon name={icon} />
              </div>
            ))}
          </div>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

export default HeroSection
